<template>
  <div>
    <b-card title="Customer Table">
      <div class="fama-table">
        <div v-if="dataLoading" class="spinner-border m-auto" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div v-else style="width: 100%">
          <b-button
            class="mb-2"
            type="submit"
            variant="primary"
            @click="showCreatePopup"
          >
            Add Customer
          </b-button>
          <DxDataGrid
            id="customer-table"
            ref="data-grid"
            width="100%"
            :show-borders="true"
            :data-source="data"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            :selection="{ mode: 'single' }"
            key-expr="id"
            @row-updated="onUpdated"
            @row-updating="onUpdating"
            @exporting="onExporting"
          >
            <DxExport :enabled="true" />
            <DxEditing
              :allow-updating="true"
              :allow-deleting="false"
              :allow-adding="false"
              mode="row"
            />
            <DxColumn data-field="id" caption="ID" :allow-editing="false" />
            <DxColumn
              data-field="customer_no"
              caption="Customer Number"
              :allow-editing="false"
            />
            <DxColumn
              data-field="sales_module_id"
              caption="Sales Module"
              :allow-editing="true">
              <DxLookup
                :data-source="salesModule"
                value-expr="id"
                display-expr="name"

              />
            </DxColumn>
            <DxColumn
              data-field="site_id"
              caption="Site"
              :allow-editing="false">
              <DxLookup
                :data-source="sites"
                value-expr="id"
                display-expr="name"
              />
            </DxColumn>
            <DxColumn data-field="user_count" caption="User Count" />
            <DxColumn data-field="firmname" caption="Firm Name" :allow-editing="false"/>
            <DxColumn data-field="firstname" caption="First Name" :allow-editing="false"/>
            <DxColumn data-field="lastname" caption="Surname" :allow-editing="false"/>
            <DxColumn data-field="email" caption="Email" :allow-editing="false"/>
            <DxColumn data-field="phone" caption="Phone" :allow-editing="false"/>
            <DxColumn data-field="expire_date" caption="Expire Date" data-type="date" :allow-editing="true"/>
            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxColumnFixing :enabled="true" />
            <DxColumnChooser :enabled="true" />
            <DxScrolling mode="standard" />
            <DxGrouping :context-menu-enabled="true" />
            <DxGroupPanel :visible="true" />
          </DxDataGrid>
        </div>
      </div>
    </b-card>
    <DxPopup
      :visible="popupVisible"
      :close-on-outside-click="false"
      :show-close-button="false"
      :show-title="true"
      :width="450"
      :height="550"
      title="Create Customer"
    >
      <DxForm
        id="form"
        :col-count="1"
        :on-content-ready="validateForm"
        :form-data="customerData"
      >
        <DxItem
          caption="Name"
          data-field="firstname"
          :validation-rules="validationRules.firstname"
        />
        <DxItem
          caption="Surname"
          data-field="lastname"
          :validation-rules="validationRules.lastname"
        />
        <DxItem
          caption="Email"
          data-field="email"
          :validation-rules="validationRules.email"
        />
        <DxItem
          caption="Phone"
          data-field="phone"
          :validation-rules="validationRules.phone"
        />
        <DxItem
          caption="Site"
          data-field="sitename"
          :validation-rules="validationRules.site"
        />
        <DxItem
          caption="Firm Name"
          data-field="firmname"
          :validation-rules="validationRules.firmname"
        />
        <DxItem
          :editor-options="{
            value: 5,
            min: 1,
            max: 20,
            showClearButton: true,
            showSpinButtons: true
          }"
          caption="User Count"
          data-field="userCount"
          :validation-rules="validationRules.usercount"
          editor-type="dxNumberBox"
        />
        <DxItem
          :editor-options="{
            dataSource: salesModule,
            value: '',
            displayExpr: 'name',
            valueExpr: 'id'
          }"
          caption="Sales Module"
          data-field="salesModule"
          editor-type="dxSelectBox"
          :validation-rules="validationRules.salesModule"
        />
        <DxItem
          caption="Expire Date"
          data-field="expire_date"
          editor-type="dxDateBox"
          :validation-rules="validationRules.expire_date"
        />
      </DxForm>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="saveButtonOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="closeButtonOptions"
      />
    </DxPopup>
  </div>
</template>

<script>
import "devextreme-vue/tag-box";
import axios from "@axios";
import { BCard, BButton } from "bootstrap-vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxForm, DxItem } from "devextreme-vue/form";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  DxDataGrid,
  DxExport,
  DxEditing,
  DxColumn,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxLookup
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import saveAs from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";

export default {
  components: {
    DxLookup,
    DxExport,
    DxEditing,
    BCard,
    BButton,
    DxForm,
    DxItem,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxColumnFixing,
    DxSearchPanel,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPopup,
    DxToolbarItem
  },
  data() {
    return {
      data: [],
      sites: [],
      salesModule: [],
      dataLoading: true,
      popupVisible: false,
      customerData: {},
      saveButtonOptions: {
        icon: "save",
        text: "Save",
        onClick: () => {
          const { firstname, email, phone, lastname } = this.customerData;
          if (!firstname || !email || !phone || !lastname) {
            this.showToast({
              variant: "error",
              title: "Error",
              text: "Please, fill all items"
            });
            return;
          }
          this.postCreateCustomer();
        }
      },
      closeButtonOptions: {
        icon: "close",
        text: "Close",
        onClick: () => {
          this.popupVisible = false;
        }
      },
      validationRules: {
        firstname: [{ type: "required", message: "Name is required." }],
        lastname: [{ type: "required", message: "Surname is required." }],
        email: [{ type: "required", message: "Email is required." }],
        phone: [{ type: "required", message: "Phone is required." }],
        site: [{ type: "required", message: "Site is required." }],
        firmname: [{ type: "required", message: "Firm Name is required." }],
        usercount: [{ type: "required", message: "User Count is required." }],
        expire_date: [{ type: "required", message: "Expire date is required." }],
        salesModule: [
          { type: "required", message: "Sales Module is required." }
        ]
      }
    };
  },
  mounted() {
    this.refreshData();
    this.getSite();
    this.getSalesModule();
  },
  methods: {
    validateForm(e) {
      e.component.validate();
    },
    showCreatePopup() {
      this.popupVisible = true;
    },
    onUpdating(e){
      console.log(e);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(`Fama-Customers}`);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: 'Arial', size: 12 };
          excelCell.alignment = { horizontal: 'left' };
        }
      }).then(function (a) {
        console.log(a);
        // const tableNamee = this.tableName;
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Fama-Customer.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    async onUpdated(e) {
      let response;
      response = await axios.post('/edit-customer-count', { id: e.data.id, user_count: e.data.user_count, expire_date : e.data.expire_date , sales_module_id : e.data.sales_module_id})
      if (response.status === 200) {
        this.showToast({
          variant: "success",
          title: "Success",
          text: "User has been updated"
        });
        this.popupVisible = false;
        this.customerData = {};
        this.getSite();
        this.refreshData();
      } else {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: e?.response?.data?.message || e?.message
        });
        this.refreshData();
      }
    },
    async postCreateCustomer() {
      let response;
      const {
        lastname,
        firstname,
        email,
        phone,
        sitename,
        firmname,
        userCount,
        salesModule,
        expire_date
      } = this.customerData;
      try {
        response = await axios.post("/create-customer", {
          firstname,
          lastname,
          phone,
          email,
          sitename,
          firmname,
          userCount,
          salesModule,
          expire_date
        });
        if (response.status === 200) {
          this.showToast({
            variant: "success",
            title: "Success",
            text: "New data has been inserted"
          });
          this.popupVisible = false;
          this.customerData = {};
          this.getSite();
          this.refreshData();
        } else {
          this.showToast({
            title: "Error",
            variant: "danger",
            text: e?.response?.data?.message || e?.message
          });
          this.refreshData();
        }
      } catch (e) {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: e?.response?.data?.message || e?.message
        });
      }
    },
    refreshData() {
      this.dataLoading = true;
      this.error = "";
      axios
        .get("/list-customer")
        .then((response) => {
          if (response.status === 200) {
            this.data = response?.data?.result || [];
            this.dataLoading = false;
          } else {
            this.error = response?.data?.message || "Unexpected API error";
            this.dataLoading = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoading = false;
        });
    },
    getSite() {
      axios
        .get("/list-site")
        .then((response) => {
          if (response.status === 200) {
            this.sites = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || "Unexpected API error";
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    getSalesModule() {
      axios
        .get("/list-sales-module")
        .then((response) => {
          if (response.status === 200) {
            this.salesModule = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || "Unexpected API error";
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    showToast({ variant = "success", title, text, icon = "InfoIcon" }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import './tables/tables.scss';
</style>
